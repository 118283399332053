<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            'id',
            { key: 'code', _style: 'background-color: #45a164;' }, // TODO: Variable
            getHelperObject('building_type'),
            getHelperObject('property_unit'),
            //'description', // TODO: Really ?
            getHelperObject('property_level'),
            getHelperObject('property_class', null, 'property_classes'),
            helpers.sharesValue,
            { key: 'owner_name', helper: { id: 'current_owner.contact.name' }, link: { id: 'current_owner.contact.id', href: '#/admin/property/building/contacts/XXX/edit' } },
            { key: 'grouper_code', helper: { id: '_grouper.code' }, link: { id: '_grouper.id', href: '#/admin/property/buildings/XXX/edit' }, _style: 'background-color: #1769ff;' }, // TODO: Variable
            { key: 'grouper_type', helper: { id: '_grouper.building_type_id', parser: id => parsers.readHelper(id, 'building_types', 'name') } },
            { key: 'grouper_unit', helper: { id: '_grouper.property_unit_id', parser: id => parsers.readHelper(id, 'property_units', 'name') } },
            'accounting_code',
            { ...helpers.validIcons, key: 'is_printable' },
            { ...helpers.validIcons, key: 'is_sendable', label: 'Sendable' },
            helpers.activeBadge
          ]"
          :config="{
            name: 'building',
            url: 'admin/buildings',
            route: '/admin/property/buildings',
            params: { _with: 'property,current_owner.contact,grouper', _lists: 'building_types,property_units,property_levels,property_classes' },
            filters: [{ key: 'building_type', config: { select_all: true } }, { key: 'property_unit', config: { select_all: true } }, { key: 'owner_name', use: 'current_owner.contact.name' }, { key: 'grouper_code', use: 'grouper.0.identifier' }, { key: 'is_printable', use_object: { 0: 'No', 1: 'Si' } }, 'active'],
            noCreate: this.periods._status === 'FAC',
            parseItems,
            _sorted: true
          }"
          :cleaner="true"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Buildings',
  mixins: [crudMixin],
  methods: {
    // TODO: Global function ? (sortBuildings)
    parseItems(parent, data) {
      const self = this
      self.view_helpers = self.$refs.crud_table.view_helpers
      return data
        .map(item => {
          return {
            ...item,
            _sorted: 0,
            _unit: self.view_helpers['property_units'][item.property_unit_id],
            _grouper: item.grouper.length ? item.grouper[0] : {},
            _grouper_unit: item.grouper.length ? self.view_helpers['property_units'][item.grouper[0].property_unit_id] : {}
          }
        })
        .sort((a, b) => {
          return a.code > b.code ? 1 : b.code > a.code ? -1 : 0
        })
        .sort((a, b) => {
          return a._unit.name > b._unit.name ? 1 : b._unit.name > a._unit.name ? -1 : 0
        })
        .sort((a, b) => {
          if (a._grouper.id === b._grouper.id) {
            if (a.id == a._grouper.id) return -1
            if (b.id == b._grouper.id) return 1
          }
          return a._grouper.code > b._grouper.code ? 1 : b._grouper.code > a._grouper.code ? -1 : 0
        })
        .sort((a, b) => {
          return a._grouper_unit.name > b._grouper_unit.name ? 1 : b._grouper_unit.name > a._grouper_unit.name ? -1 : 0
        })
        .map((item, index) => {
          return { ...item, _sorted: index + 1 } // TODO: Allow reset order
        })
    }
  }
}
</script>
