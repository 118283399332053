var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          'id',
          { key: 'code', _style: 'background-color: #45a164;' }, // TODO: Variable
          _vm.getHelperObject('building_type'),
          _vm.getHelperObject('property_unit'),
          //'description', // TODO: Really ?
          _vm.getHelperObject('property_level'),
          _vm.getHelperObject('property_class', null, 'property_classes'),
          _vm.helpers.sharesValue,
          { key: 'owner_name', helper: { id: 'current_owner.contact.name' }, link: { id: 'current_owner.contact.id', href: '#/admin/property/building/contacts/XXX/edit' } },
          { key: 'grouper_code', helper: { id: '_grouper.code' }, link: { id: '_grouper.id', href: '#/admin/property/buildings/XXX/edit' }, _style: 'background-color: #1769ff;' }, // TODO: Variable
          { key: 'grouper_type', helper: { id: '_grouper.building_type_id', parser: function (id) { return _vm.parsers.readHelper(id, 'building_types', 'name'); } } },
          { key: 'grouper_unit', helper: { id: '_grouper.property_unit_id', parser: function (id) { return _vm.parsers.readHelper(id, 'property_units', 'name'); } } },
          'accounting_code',
          Object.assign({}, _vm.helpers.validIcons, {key: 'is_printable'}),
          Object.assign({}, _vm.helpers.validIcons, {key: 'is_sendable', label: 'Sendable'}),
          _vm.helpers.activeBadge
        ],"config":{
          name: 'building',
          url: 'admin/buildings',
          route: '/admin/property/buildings',
          params: { _with: 'property,current_owner.contact,grouper', _lists: 'building_types,property_units,property_levels,property_classes' },
          filters: [{ key: 'building_type', config: { select_all: true } }, { key: 'property_unit', config: { select_all: true } }, { key: 'owner_name', use: 'current_owner.contact.name' }, { key: 'grouper_code', use: 'grouper.0.identifier' }, { key: 'is_printable', use_object: { 0: 'No', 1: 'Si' } }, 'active'],
          noCreate: this.periods._status === 'FAC',
          parseItems: _vm.parseItems,
          _sorted: true
        },"cleaner":true},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }